<template>
  <header>
    <router-link to="/" class="logo"></router-link>
    <div class="leftslot">
      <slot name="left" />
    </div>
    <nav>
      <div class="menu" ref="menu" @click="menuOpen = !menuOpen">
        <span class="arrow"></span>
        {{$store.getters.userFullName}} <span class="role" v-if="role">{{role}}</span>
      </div>      
      <div class="menuitems" v-if="menuOpen">
        <router-link to="/profile">Edit Profile</router-link>
        <!-- <router-link to="/users">Manage Users</router-link> -->
        <a @click="logout">Log Out</a>
      </div>

    </nav>
    <div class="rightslot">
      <slot name="right" />
    </div>
  </header>
</template>

<script>
import {AUTH_LOGOUT} from "../store/actions/auth";

export default {
  name: 'Header', 
  data: function(){
    return{
      menuOpen: false,
    }
  },
  computed: {
    role(){
      if(this.$store.getters.isAdmin)
        return '[Admin]'
      if(this.$store.getters.isOrgAdmin)
        return '[OrgAdmin]'
      return null
    }
  },
  methods: {
    async logout(){
      await this.$store.dispatch(AUTH_LOGOUT); 
      if(this.$route.path == '/presentation')
        window.close()         
    },
    closeMenu: function(e){
      const withinBoundaries = e.composedPath().includes(this.$refs.menu)
      if(this.menuOpen && (e.keyCode == 27 || (e.keyCode == undefined && !withinBoundaries)))
        this.menuOpen = false;
    }
  },
  mounted: function(){
    window.addEventListener("keyup", this.closeMenu, false);
    window.addEventListener("click", this.closeMenu, false);
  },
  beforeDestroy: function(){
    window.removeEventListener("keyup", this.closeMenu, false);
    window.removeEventListener("click", this.closeMenu, false);
  }
}
</script>

<style lang="scss">

header{
  height: 80px;
  border-bottom: $bordermixin;
  background-color: white;

  &.sticky{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 21;
  }
  
  .logo{
    width: 164px;
    height: 58px;
    float: left;
    margin: 11px 24px;
    background-image: url("../assets/inseadvr-logo.png");
    background-size: contain;    
  }
  a.logo:hover{
    background-color: white;
    background-image: url("../assets/inseadvr-logo.png");
    background-size: contain;  
  }

  .leftslot{
    float:left;
  }
  .rightslot{
    float:right;
  }

  nav{
    font-size: 15px;
    line-height: 18px;
    float: right;
    margin: 24px;

    .menu{
      padding: 7px;

      &:hover{
          background: #E9F4EA;
          border-radius: 5px;
          cursor: pointer;
      }
    }

    .role{
      font-size: 12px;
      vertical-align: bottom;
      font-weight: bold;
      padding-left: 5px;
    }

    .menuitems{
      padding: 16px;
      position: absolute;
      right: 20px;
      background: white;
      border: $bordermixin;
      box-sizing: border-box;
      box-shadow: $shadowS;
      border-radius: 5px;
      z-index: 100;

      a {
        display: block;
      }
    }

    span.arrow{
      background-image: url("../assets/arrow-icon.svg");
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      width: 14px;
      height: 9px;
      display: inline-block;
      margin-right: 8px;      
    }
  }
}

</style>
