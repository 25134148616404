<template>
    <div class="sidebar2">  
      <router-link v-if="$store.getters.isAdmin || $store.getters.isProfessor || $store.getters.isOrgAdmin" to="/upcoming">
      <img src="../assets/dashboard.svg">
      <br>
      Dashboard
      </router-link> 

      <router-link v-if="$store.getters.isAdmin || $store.getters.isProfessor || $store.getters.isOrgAdmin" to="/xrlibrary">
      <img src="../assets/podium.svg">
      <br>
      XR Library
      </router-link>  

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/sessions2">
      <img src="../assets/sessions.svg">
      <br>
      Sessions
      </router-link>  

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/users2">
      <img src="../assets/participants.svg">
      <br>
      Users
      </router-link> 

      <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" :to="$store.getters.avrisyncMvp ? '/devices3' : '/devices2'">
      <img src="../assets/vrglasses1.svg">
      <br>
      Devices
      </router-link> 

      <router-link v-if="$store.getters.isAdmin" to="/library">
      <img src="../assets/books.svg" style="width:30px;">
      <br>
      Experiences
      </router-link> 

      <a class="support" :href="portalHost + '/supporthub'" target="_blank">
        <img src="../assets/g177.svg">
        <br>
        Support Hub
      </a>
    </div>
</template>



<script>

export default {
  name: 'Sidebar2',
  computed:{
    portalHost(){
      return process.env.VUE_APP_PORTAL_URL
    }  
  },
  methods: {
    toggle() {     
      this.$store.commit('toggleSidebar');      
    }  
  }
}
</script>

<style lang="scss">

.sidebar2{
    width: 100px;    
    height: 100%;
    background-color: #fff;
    padding: 4px 4px;
    position: fixed;
    left: 0;
    top: var(--size-header);

    @media (min-height: 670px) {
      padding-top: 50px;
    }

    a, a:visited{
      display: block;
      font-size: 14px;
      color: $text-grey;
      padding: 5px;
      text-align: center;
      margin-bottom: 5px;
      text-decoration: none !important;

      img{
        max-width: 100%;
        max-height: 25px;
      }

      @media (min-height: 670px) {
        font-size: 16px;
        img {
          max-height: 40px;
        }
      }

      &.router-link-active{
        background-color: #edf6ef;
        color: $text-green1;        
      }

      &.support{
        font-size: 12px;
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 100px;
        text-align: center;
        margin: 0;
      }
    }
}

.themev2 .sidebar2{
  a, a:visited{
    color: $text-grey;
      &.router-link-active{
        background-color: #edf6ef;
        color: $text-green1;        
      }
  }
}

</style>
