import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import axios from 'axios';
import { humanFileSize } from '@/utils.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarCollapsed: false,
    editorWebContentRootPath: '',
    showGettingStarted: undefined,
    showLoader: true,
    experiences: [],
    drupalExperiences: [],
    avrisdebug: false
  },
  getters: {    
    showGettingStarted(state){ 
      return (state.showGettingStarted || state.showGettingStarted == undefined) && !localStorage.getItem('skipintro') && !sessionStorage.getItem('skipintro')
    },
    orderedExperiences(state){
      return state.experiences.sort((a, b) => a.name?.localeCompare(b.name, undefined, {sensitivity: 'base'})).map(e => ({...e, fileSizeLabel: `${e.name} - ${humanFileSize(e.size)}` })) 
    }
  },
  mutations: {  
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    showGettingStarted(state) {
      localStorage.removeItem('skipintro')
      sessionStorage.removeItem('skipintro')
      state.showGettingStarted = true
    },
    hideGettingStarted(state, dontShowAgain) {
      // if dontshow -> store in local storage
      if(dontShowAgain)
        localStorage.setItem('skipintro', true)      
      else
        sessionStorage.setItem('skipintro', true)
      state.showGettingStarted = false
    },
    setExperiences(state, exp){
      state.experiences = exp
    },
    setDrupalExperiences(state, exp){
      state.drupalExperiences = exp
    }
  },
  actions: {
    async getExperiences({commit}){ 
      let resp = await axios({ url: "experiences" }) 
      commit('setExperiences', resp.data)
    },
    async getDrupalExperiences({commit}){ 
      let tmp
      if(process.env.VUE_APP_ENV == 'local'){
        tmp =  [
    {
        "id": "147",
        "title": "Mission to Mars – New horizons",
        "playbook_link": "/playbooks/mission-mars-playbook/summary",
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-09/Spaceship4.png",
            "description": "<p>Leadership and effective communication</p>"
        },
        "description": "<p><a href=\"/node/96\">Go to Playbook</a></p><p>The year is 2055. ARES 2 space shuttle is on its way to Mars when a shocking accident occurs. Now, ARES 2 must lose a significant part of its payload to be able to safely land on Mars.&nbsp;</p><p>In this experience, participants are immersed in0 ARES Mining Mission Control and in the Mars Base Camp, asked to make a tough decision on the payload cut. Working in teams, participants will gather information and share it between themselves to achieve the best solution, and save crew life and uphold mission objectives. The clock is ticking as the ship gets closer to Mars.&nbsp;What will you decide?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-03/vr-workshop-oct-2022-7.jpg",
                "card_title": "Introduction",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-03/vr-workshop-oct-2022-6.jpg",
                "card_title": "Briefing",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-03/Group%206520%403x.webp",
                "card_title": "CCTV",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-03/vr-workshop-oct-2022-5.jpg",
                "card_title": "Team Interviews",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "/sites/xr/files/2023-03/vr-workshop-oct-2022-4.jpg",
                "card_title": "Item Selection",
                "card_sub_title": "Segment 5"
            }
        ],
        "tags": [
            "Leadership",
            "Team Dynamics",
            "Decision Making",
            "Communication",
            "DEI",
            "Goal Setting",
            "Management",
            "Managing Change",
            "OB",
            "Problem Solving",
            "Teamwork",
            "Crisis management"
        ],
        "learning_objectives": [
            "Effective decision making in complex and ambiguous situations",
            "Sharing the uncommon - how to tackle information biases in teams?",
            "Listening to minority voice in team decision making",
            "Encouraging minority dissent and listening to others’ perspectives",
            "Balancing efficiency with curiosity and innovation",
            "Debriefing – how to learn from success and failures"
        ],
        "unique_features": [
            " Questions",
            " Interactivity",
            " Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_qumbxx09",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Mars_Spaceship_Image_V01-SharpenAI-Softness_V01_0.png",
            "video_length": "02:02"
        },
        "video_detail": {
            "creators": [
                "Charles Galunic",
                "Jennifer Petriglieri",
                "Spencer Harrison"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "3 Hours"
        }
    },
    {
        "id": "152",
        "title": "Lean Operation At CLAAS ",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-03/Mask%20Group%203106.webp",
            "description": "<p>A VR immersive learning experience</p>"
        },
        "description": "<p><a href=\"/playbooks/lean-operation-playbook/summary\">Go to playbook</a></p><p>“Go, look, and see for yourself” (genchi gembutsu) – this basic principle of lean manufacturing, has been rejuvenated in this immersive learning experience. Participants are guided through an experiential journey on the CLAAS tractor factory assembly line, exposing them to lean management best practices and applications. Your participants will have the opportunity to get a behind-the-scenes look into the factory, explore freely and see lean principles in action. How will they apply what they learned to their own workplace?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/p4HLKblarPThUQDhGkREsIk-4SEjngY6MGRpTWlOjUE.jpg",
                "card_title": "Andon Call",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer%20segment%201.jpg",
                "card_title": "Main Assembly",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/qyOjXzYUCFnNH5FSFwuI8dRtVZLhA6Sp6sSdCfvk2jM.jpg",
                "card_title": "Cabin Assembly",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer%20poster.png",
                "card_title": "Morning Briefing",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "",
                "card_title": "Cleaning Process",
                "card_sub_title": "Segment 5"
            },
            {
                "background_image": "/sites/xr/files/kaltura/poster/131391294.webp",
                "card_title": "Picking Process",
                "card_sub_title": "Segment 6"
            },
            {
                "background_image": "/sites/xr/files/2023-03/appendix2%402x.webp",
                "card_title": "Plant Layout",
                "card_sub_title": "Segment 7"
            }
        ],
        "tags": [
            "Operations",
            "Supply chain management",
            "Consuling",
            "Decision Making",
            "Goal Setting",
            "Problem Solving",
            "Service Process"
        ],
        "learning_objectives": [
            "Introduce learners to core principles of lean management",
            "Explore lean management practices and applications",
            "Apply lean management to broader organizational and management practices.",
            "Experience plant auditing, reading a plant fast, and rating it  ",
            "Suggesting improvement ideas for the plant’s operation practicum",
            "Learn how digital is intertwined with teamwork, and apply the case for discussions on value creation and immersive learning as a digital age solution."
        ],
        "unique_features": [
            " Questions"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_ew5oekt4",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Lean%20operations.png",
            "video_length": "01:34"
        },
        "video_detail": {
            "creators": [
                "Guillaume Roels",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "90",
            "type_of_experience": [
                "Interactive Documentary"
            ],
            "recommended_learning_time": "1/2 day"
        }
    },
    {
        "id": "155",
        "title": "Al Mouneer - Envisioning the Opportunity",
        "playbook_link": "/playbooks/al-mouneer-playbook/summary",
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/Almouneer%20poster.png",
            "description": "<p>Leading growth and new market penetration</p>"
        },
        "description": "<p><a href=\"https://xr.insead.edu/playbooks/al-mouneer-playbook/summary\">Go to playbook</a></p><p><br>This experience exposes learners to the challenges associated with localization of strategy in a healthcare business organization, touching on regulation, culture, and other issues common in new market penetration. Learners are exposed to the efforts invested to make an impact on the diabetes pandemic, by using digital tools, and mindset changing efforts. A written case and an interactive documentary experience take learners to Egypt and Abu Dhabi. The case invites participants to an immersive visit of medical clinics and a deeper understanding of the impact of modern lifestyles on the health of populations, as well as the efforts to help rectify this reality.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-03/Image%205%403x.webp",
                "card_title": "Segment 1",
                "card_sub_title": " Introduction to Al Mouneer"
            },
            {
                "background_image": "/sites/xr/files/media-icons/generic/no-thumbnail.png",
                "card_title": "Segment 2",
                "card_sub_title": "Dokki clinic"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer.png",
                "card_title": "Segment 3",
                "card_sub_title": "Shubra clinic"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer_0.png",
                "card_title": "Segment 4",
                "card_sub_title": "Al Mouneer as a tech company"
            },
            {
                "background_image": "/sites/xr/files/kaltura/poster/Almouneer.png",
                "card_title": "Segment 5",
                "card_sub_title": "Diabetes and lifestyle in Egypt"
            },
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/xIAEgv5XttJpeXQvhUVhosNqhATtMAwCWBUKILXaLGk.jpg",
                "card_title": "Segment 6",
                "card_sub_title": "The future - UAE and Abu Dhabi"
            }
        ],
        "tags": [
            "Leadership",
            "Strategy",
            "Entrepreneurship",
            "Operations",
            "Supply chain management",
            "Governance",
            "Management",
            "Managing Change",
            "Operations",
            "Service Process"
        ],
        "learning_objectives": [
            "Localizing strategy",
            "Creating synergies through strategic partnerships",
            "Building effective service strategies",
            "Impactful practices in customer management",
            "Market penetration through service adaptation",
            "Healthcare systems management"
        ],
        "unique_features": [
            "Questions"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_aadj27st",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Almouneer.png",
            "video_length": "03:24"
        },
        "video_detail": {
            "creators": [
                "Daniel Landau",
                "Ithai Stern",
                "Alon Epstein",
                "Chengyi Lin",
                "Ridhima Aggarwal"
            ],
            "duration_of_vr_seconds": "19",
            "type_of_experience": [
                "Interactive Documentary"
            ],
            "recommended_learning_time": "90"
        }
    },
    {
        "id": "156",
        "title": "Avocado Shake",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/AVOCADO-%20trailer.png",
            "description": "<p>Creating a sustainable competitive advantage&nbsp;</p>"
        },
        "description": "<p><a href=\"/playbooks/avocado-shake-playbook/summary\">Go to Playbook</a></p><p>Learners will be virtually immersed into a peaceful beach in Zanzibar with white sand, clear turquoise waters, and a variety of exotic juice bars. But how do customers choose which bar to approach? They are situated close to one another, look the same and sell similar products.&nbsp;</p><p>Participants will meet two business owners -&nbsp;Gabriella at Bravocado, and Robert at Zanzi-Bar -&nbsp;and gather details in order to figure out their different business outlooks and competitive advantage. Based on their acquired knowledge, participants will also have an opportunity to consult Gabriella and give her some strategic advice on how to expand her avocado shake business. What would be her optimal option to support sustainable growth?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/kaltura/poster/UD-Hero_0.jpg",
                "card_title": "The industry",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-04/ABOUT%20IMAGE.jpeg",
                "card_title": "The strategic dilemma",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-04/ABOUT%20IMAGE1.jpeg",
                "card_title": "Resolution",
                "card_sub_title": "Segment 3"
            }
        ],
        "tags": [
            "Strategy",
            "Consuling",
            "Decision Making",
            "Entrepreneurship",
            "Goal Setting",
            "Managing Change"
        ],
        "learning_objectives": [
            "Identify and define strategic resources which enable a sustainable competitive advantage ",
            "Analyze the corporate’s strategic positioning compared to market and competitors",
            "Practice the consultant’s role",
            "Apply the VRIO model to assess sustainable competitive advantage ",
            "Identify core competencies and plan growth around them ",
            "Compare strategic outlooks of businesses, and their relative advantages ",
            "Asses how to approach a strategic dilemma, analyze the situation, and come up with a viable suggestion",
            "Develop different strategies for business growth",
            "Devise innovative solutions for real problems"
        ],
        "unique_features": [
            " Questions",
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_yaxcwnzn",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/AVOCADO-%20trailer.png",
            "video_length": "01:37"
        },
        "video_detail": {
            "creators": [
                "Ithai Stern",
                "Daniel Landau"
            ],
            "duration_of_vr_seconds": "4",
            "type_of_experience": [
                "Immersive case study"
            ],
            "recommended_learning_time": "60-90"
        }
    },
    {
        "id": "160",
        "title": "Surfs Up! Designing Effective Solutions",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/Creativity_background_0.png",
            "description": "<p>A systematic approach to creativity &amp; ideation</p>"
        },
        "description": "<p>Katie and TJ are best friends getting ready to compete in a surfing competition. Unfortunately, they discover they have arrived at the wrong beach. Things get even more complicated as they encounter a series of challenges preventing them from getting to the competition on time. Will participants be able to help these unlucky friends to solve their problems? Through a series of interactive interfaces and innovative ways, participants will select options and objects in the virtual sphere.&nbsp;&nbsp;&nbsp;&nbsp;</p><p>This immersive experience will reveal assumptions and biases that limit creative thinking. Participants will be challenged to think “inside the box” to look for solutions from the systematic creativity toolbox.</p>",
        "cards": [
            {
                "background_image": "",
                "card_title": "Segment 1",
                "card_sub_title": "Flat tire"
            },
            {
                "background_image": "/sites/xr/files/2023-08/avocado%20background.png",
                "card_title": "Segment 2",
                "card_sub_title": "Missing bolts"
            },
            {
                "background_image": "/sites/xr/files/kaltura/poster/Team_Brief_Images_V01-SharpenAI-Motion_800x500.jpg",
                "card_title": "Segment 3",
                "card_sub_title": "Stuck in the sand"
            }
        ],
        "tags": [
            "Problem Solving",
            "Creativity & Innovation",
            "Entrepreneurship"
        ],
        "learning_objectives": [
            "Experience the barriers to creative thinking ",
            "Acquire ”inside the box” creativity tools ",
            "Improve creativity with systematic inventive thinking ",
            "Apply the systematic approach to various problems and business cases"
        ],
        "unique_features": [
            " Questions",
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_137ppchi",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Creativity_background.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Ella Miron-Spektor (INSEAD OB)",
                "Amitava Chattopadhyay (INSEAD Marketing)",
                "Jacob Goldenberg (Reichman U)",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "20",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "90- full course"
        }
    },
    {
        "id": "162",
        "title": "The Corporate Drawbridge",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/drawbridge%20background_0.png",
            "description": "<p>Value based decision making</p>"
        },
        "description": "<p>Elysium, a cybersecurity company, is holding a cultural event in honor of its CEO's retirement, and celebrating its new strategic turnaround. But a day before the show, the head of sales (and a major stakeholder in the event) quits the company. The other team members are surprised and worried about the implications for the company and the upcoming event. Can the show really go on?<br>Participants immerse themselves as the new executive assistant of the company, hired by the new CEO to be his eyes and ears on the ground. Learners will have to be very vigilant to understand the dynamics between company sub-groups and cultures, and how these might affect the company. This immersive experience will elicit social and cognitive biases. Participants will play an active role and make decisions based on their own judgment and understanding of the situation and characters.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Lean%20operations-%20segment%202.png",
                "card_title": "The Lobby",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Lean%20operations-%20segment%202_0.png",
                "card_title": "The Team",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Lean%20operations-%20segment%204.png",
                "card_title": "Green room",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Lean%20operations-%20segment%203.png",
                "card_title": " The Stage",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer_background.png",
                "card_title": "Personal message",
                "card_sub_title": "Segment 5"
            }
        ],
        "tags": [
            "Leadership",
            "Team Dynamics",
            "Governance",
            "Decision Making",
            "DEI",
            "Communication",
            "Consuling",
            "Management",
            "Managing Change",
            "OB"
        ],
        "learning_objectives": [
            "Implications of transition of power and its effects on individuals, groups, and organizations",
            "Effective change management and engaging key stakeholders",
            "Diversity and Inclusion",
            "Growing through challenges - encouraging a culture of accountability in organizations ",
            "The role of culture in organizational dynamics",
            "Building a culture of trust  ",
            "Micro-cultures in organizations - how to enhance a sense of \"One Company\" identity"
        ],
        "unique_features": [
            "Questions",
            "Behavioral analytics",
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_1byo3dy4",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/drawbridge%20background.png",
            "video_length": "02:06"
        },
        "video_detail": {
            "creators": [
                "Daniel Landau",
                "Alon Epstein",
                "Derek Deasy"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Immersive case study"
            ],
            "recommended_learning_time": "4 hours"
        }
    },
    {
        "id": "164",
        "title": "Paradoxical Leadership at FMB",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/fmb%20background_0.png",
            "description": "<p>Achieving sustainable conservation for Fundação Maio Biodiversidade (FMB)</p>"
        },
        "description": "<p><a href=\"/playbooks/fmb-playbook/summary\">Go to playbook</a><br><br>It is another beautiful day on Maio, one of the least visited and inhabited islands of Cabo Verde. The team of FMB is celebrating its tenth anniversary and, more importantly, its future direction.<br>FMB is a non-profit organization dedicated to the conservation of the environment on the island. It has grown from a small start-up to an organization which is firmly embedded in the community.&nbsp; In this immersive experience, participants will take on the perspective of the next leader of FMB and try to address the competing demands of conservation and community development. Participants need to determine FMBs next stage of development as Maio is about to embark on major infrastructure projects that could overwhelm the delicate ecosystem. Can they help FMB take on these challenges?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/drawbridge%20segment%202.png",
                "card_title": "Segment 1",
                "card_sub_title": "Introduction to FMB and Maio"
            },
            {
                "background_image": "/sites/xr/files/2023-05/drawbridge%20segment%203.png",
                "card_title": "Segment 2",
                "card_sub_title": "Oppose little Africa vs. Accept little Africa"
            },
            {
                "background_image": "/sites/xr/files/2023-05/drawbridge%20segment%204.png",
                "card_title": "Segment 3",
                "card_sub_title": "FMB activity on Maio"
            },
            {
                "background_image": "/sites/xr/files/2023-05/7.PNG",
                "card_title": "Segment 4",
                "card_sub_title": "Interview Elisa"
            },
            {
                "background_image": "/sites/xr/files/2023-05/fmb%20background.png",
                "card_title": "Segment 5",
                "card_sub_title": "Meet the team and leader"
            }
        ],
        "tags": [
            "Strategy",
            "Decision Making",
            "Sustainability",
            "Consuling",
            "DEI",
            "Entrepreneurship",
            "Goal Setting",
            "Management",
            "Managing Change",
            "OB"
        ],
        "learning_objectives": [
            "Embracing the concept of paradoxical leadership and the constant state of change and adaptation.",
            "Strategic decision-making under conditions of diverse and often opposing constraints",
            "Balancing competing demands at the organization, team, and leadership levels",
            "Balancing value-based objectives versus financial needs ",
            "Reflecting on different approaches to form teams",
            "Facing challenges and opportunities associated with team diversity and multicultural teams"
        ],
        "unique_features": [
            "Questions ",
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_k3qxx1dm",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/fmb%20background_0.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Ella Miron-Spektor",
                "Ithai Stern",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Interactive Documentary"
            ],
            "recommended_learning_time": "2-3 hours"
        }
    },
    {
        "id": "165",
        "title": "The Tekka Market Journey",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/Tekka_background_3.png",
            "description": "<p>Customer centric innovation</p>"
        },
        "description": "<p><a href=\"https://xr.insead.edu/playbooks/tekka-market/summary\">Go to playbook</a></p><p><br>Take a virtual tour of Tekka Market, Singapore’s most vibrant wet market. Follow shoppers and vendors in their daily routine, and do a walk through of different stalls selling vegetables, seafood, meat, and spices. In this experience learners analyze and locate areas where they think service and experience can be improved, applying insights to their own reality.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/2_2.PNG",
                "card_title": "Segment 1",
                "card_sub_title": " Welcome to Tekka Market"
            },
            {
                "background_image": "/sites/xr/files/2023-05/3_1.PNG",
                "card_title": "Segment 2",
                "card_sub_title": "Meat section"
            },
            {
                "background_image": "",
                "card_title": "Segment 3",
                "card_sub_title": "Vegetable Section"
            },
            {
                "background_image": "",
                "card_title": "Segment 4",
                "card_sub_title": "Spices Section"
            },
            {
                "background_image": "/sites/xr/files/2023-05/13333_0.jpeg",
                "card_title": "Segment 5",
                "card_sub_title": "Food Court"
            },
            {
                "background_image": "/sites/xr/files/2023-05/144444.jpeg",
                "card_title": "Segment 6",
                "card_sub_title": "Seafood section"
            }
        ],
        "tags": [
            "Service Process",
            "Problem Solving",
            "Consuling",
            "Creativity & Innovation",
            "Supply chain management"
        ],
        "learning_objectives": [
            "Learn how to create delightful, convenient, and sustainable customer journeys",
            "Apply the design thinking model to generate innovation",
            "Generate effective initiatives to create better customer experiences"
        ],
        "unique_features": [
            " Questions"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_q8967lcx/",
                    "partner_id": "2296822",
                    "uiconf_id": "39810292",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Tekka_background_0.png",
            "video_length": "10:48"
        },
        "video_detail": {
            "creators": [
                "Manuel Sosa",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "22",
            "type_of_experience": [
                "site visit"
            ],
            "recommended_learning_time": "60-120 min"
        }
    },
    {
        "id": "172",
        "title": "Mission To Mars - The Airlock",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/mars%20arilock%20bacground_0.png",
            "description": "<p>Developing and assessing crisis management skills</p>"
        },
        "description": "<p>As the sun sets on the Mars horizon, two astronauts approach the Ares Mining base camp. A wild sandstorm is raging around them, making it hard to see. Through the light cast down from the airlock shaft, one team member can be seen reaching out to open the door. It slides open and immediately, they both step in, leaving a cloud of dust behind them.</p><p>In this experience, participants will hone their crisis management skills in one of two conditions:&nbsp;</p><p>Condition 1: Participants are immersed together with the team members inside the airlock. The door is locked&nbsp;and the air is becoming scarce.&nbsp;What should they do to survive?&nbsp;</p><p>Condition 2: Learners are immersed in the control room of the Ares Mining base camp on Mars. They have to figure out what caused the airlock to seal and help the other team members get out safely. Will they make it on time?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer%20segment%204.png",
                "card_title": "Segment 1",
                "card_sub_title": "The Airlock"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer%20segment%205.png",
                "card_title": "Segment 2",
                "card_sub_title": "Control room"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Almouneer%20segment%202.png",
                "card_title": "Segment 3",
                "card_sub_title": "After-action-review"
            }
        ],
        "tags": [
            "Leadership",
            "Team Dynamics",
            "Decision Making",
            "Communication",
            "Consuling",
            "Management",
            "OB",
            "Problem Solving",
            "Crisis management"
        ],
        "learning_objectives": [
            "Effective communication ",
            "Building trust ",
            "Personal and organizational resilience",
            "After action review – conducting effective interviews",
            "Process management and adapting action in times of uncertainty",
            "Value-based decision making",
            "Accountability - leadership in times of crisis"
        ],
        "unique_features": [
            "Questions",
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_2882fnrg",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Screenshot%202023-09-28%20132555.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Thomas Hellwig",
                "Alon Epstein",
                "Daniel Landau"
            ],
            "duration_of_vr_seconds": "12",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "90"
        }
    },
    {
        "id": "174",
        "title": "Service Operations Strategy at Miraclefeet",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/MF_background_0.png",
            "description": "<p>Strategic operations in service organizations</p>"
        },
        "description": "<p><a href=\"/playbooks/miraclefeet-playbook/summary\">Go to playbook</a></p><p>The Usa River Clinic in Tanzania is full of patients today. Clinic personnel care for young children suffering from clubfoot -&nbsp;a congenital birth defect limiting mobility -&nbsp;while their mothers hold them in their arms. Patients from all over Tanzania can come to the clinic thanks to MiracleFeet, a non-profit organization providing access to treatment for&nbsp;clubfoot in low income countries. In this experience, participants will immerse as MiracleFeet's advisers, and explore its unique strategic, organizational, and operational model. MiracleFeet is facing significant changes as they plan to expand their offering to middle income countries, and participants are challenged to help them achieve their goals. What new strategies will they develop?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Lean%20operations-%20segment%206.jpg",
                "card_title": "Segment 1",
                "card_sub_title": "Usa River Clinic"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background.png",
                "card_title": "Segment 2",
                "card_sub_title": "The Service Journey"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_segment%202.png",
                "card_title": "Segment 3",
                "card_sub_title": "Scaling and Digitizing"
            },
            {
                "background_image": "/sites/xr/files/kaltura/poster/Tekka_background.png",
                "card_title": "Segment 4",
                "card_sub_title": "Leadership Interviews"
            },
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/UHyx1EsR3vkDTWUy4hwWfXm1Eu_MNeHcb1eolgjf-PI.jpg",
                "card_title": "Segment 5",
                "card_sub_title": " Home visitst"
            }
        ],
        "tags": [
            "Supply chain management",
            "Consuling",
            "Decision Making",
            "Management",
            "Operations",
            "Service Process"
        ],
        "learning_objectives": [
            "Understanding service journeys and the implications of relationships within the service triangle - front stage, organizations, and customers.",
            "Appreciating the subjective experiences of the different players in each point of the triangle, and streamline the processes",
            "Improving service processes, reduce bottlenecks, redundancy and waste",
            "Understand healthcare as a service – this experience is specifically designed for healthcare organizations",
            "Exposing learners to the unique challenges faced by non-profit health organizations",
            "Developing alternative strategies for new market entry",
            "Analyzing the organization Miraclefeet's value proposition, key success factors and potential risks",
            "Expanding and adapting existing service models to new locations ",
            "Comparing different service designs and their effect on patient experience and care process"
        ],
        "unique_features": [
            "Interactivity",
            "Questions"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_9kgyje52",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/miraclefeet.png",
            "video_length": "01:41"
        },
        "video_detail": {
            "creators": [
                "Stephen Chick",
                "Alon Epstein",
                "Daniel Landau"
            ],
            "duration_of_vr_seconds": "26",
            "type_of_experience": [
                "site visit"
            ],
            "recommended_learning_time": "90 min- 1/2 day"
        }
    },
    {
        "id": "176",
        "title": "Negotiating the Future of Spiral Flow",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/spiral%20flow%20background_0.png",
            "description": "<p>Navigating through leadership, governance, and conflict&nbsp;</p>"
        },
        "description": "<p><a href=\"/playbooks/spiral-flow-playbook/summary\">Go to playbook</a></p><p>See how leadership, decision making, and negotiation come to life in this immersive experience, based on a real-life case. Participants take on a first-person role in an unfolding organizational conflict, when called upon to help make strategic decisions in the evolving narrative of a high-paced Fintech services company. Participants will take the role of a personal advisor, trying to look out for their side’s best interests. They will be confronted with real life dilemmas, forced to make hard decisions that will determine the outcome of the case. Will they be able to help navigate this complicated situation?</p><p>Learners can experience the evolving conflict from two perspectives: The hired CEO (Mansour) or the Founders’ (Alex) perspective.&nbsp;</p><p>See how priming and taking on different roles deeply influence the positions and outcome of this adaptive and interactive simulation.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_1.png",
                "card_title": "Segment 1",
                "card_sub_title": "New Beginnings"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_2.png",
                "card_title": "Segment 2",
                "card_sub_title": "Team Celebration"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_segment%202_0.png",
                "card_title": "Segment 3",
                "card_sub_title": " Leadership Meeting"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_segment%204_0.png",
                "card_title": "Segment 4",
                "card_sub_title": "Mediation & resolutions"
            }
        ],
        "tags": [
            "Leadership",
            "Team Dynamics",
            "Entrepreneurship",
            "Negotiations",
            "Governance",
            "Decision Making",
            "Communication",
            "Consuling",
            "Goal Setting",
            "Management",
            "Mediation",
            "Negotiations",
            "OB"
        ],
        "learning_objectives": [
            "Apply effective negotiations and communications approaches",
            "Expose learners to the complex challenges of senior-level leadership",
            "Hone personal leadership skills",
            "Provide an effective framework for transitioning into senior management positions",
            "Preparation and goal setting for negotiation situations",
            "Achieving team engagement around organizational goals ",
            "Managing communication gaps within teams",
            "Applying different approaches to measuring and evaluating employee performance",
            "Leading in complex and unknown situations",
            "Navigating relationships between board and management "
        ],
        "unique_features": [
            "Questions ",
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_ja4mmt7f",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/spiral%20flow%20segment%203.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Horacio Falcao",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": " 3 hours - 1 day"
        }
    },
    {
        "id": "179",
        "title": "Immersive Ecosystems",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/ecosystem%20background_0.png",
            "description": "<p>Choosing between centralized and decentralized ecosystems&nbsp;</p>"
        },
        "description": "<p>When the pandemic suddenly displaced hundreds of students from INSEAD campuses, the INSEAD VR Initiative went into immediate action.&nbsp; With courses shutting down at lightning speed,&nbsp; everyone wanted a VR headset. The Hardware solution was narrowed down to two competing VR brands, Oculus by Facebook, and Pico, manufactured by a small Chinese hardware company. But instead of a simple decision based on price points and logistical availabilities, it was clear that the choice would be between two entirely different ecosystems. To make the right decision fast a coherent business strategy had to be developed, integrating the chosen ecosystem into the existing operational structure. This case is based on a framework developed by Andrew Shipilov, a professor of strategy at INSEAD.&nbsp;</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_segment%203_0.png",
                "card_title": "Segment 1",
                "card_sub_title": "Eco systems"
            }
        ],
        "tags": [
            "Creativity & Innovation",
            "Entrepreneurship",
            "Governance",
            "Strategy"
        ],
        "learning_objectives": [
            "Illustrating the challenges facing ecosystem builders",
            "Understanding the five key roles of Corporate Ecosystems",
            "How to build the Right Ecosystem for Innovation",
            "Experience the advantages and capabilities of VR immersive learning"
        ],
        "unique_features": [
            "Questions "
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_z90euyww",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/EcoSystem_Big_720X370_V3.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Andrew Shipilov",
                " Brian Henry",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "5",
            "type_of_experience": [
                "Interactive Documentary"
            ],
            "recommended_learning_time": "90 min"
        }
    },
    {
        "id": "183",
        "title": "Tower Markets ",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/tower%201.png",
            "description": "<p>Confronting social &amp; racial inequalities in VR</p>"
        },
        "description": "<p>The experience follows a proven scenario in business studies – Tower Markets.&nbsp;Originally designed to test decision making processes, this scenario requires participants to make decisions based on information shared by different characters regarding a shopping mall that is just about to be built.&nbsp; Participants are assigned the task to make a series of decisions about this new joint venture.</p><p>Information is communicated by different individuals, each of different gender and/or race over the five conditions.</p><p>Learners will be exposed to the different conclusions that participants have reached in each condition, while keeping all other factors constant. This will serve faculty to debrief and improve decision making processes in teams and raise awareness to the attention bias. This experience offers an innovative tool, opening a new window to understanding flawed decision making and discriminatory behaviour in teams and organizations. As opposed to currently existing DEI training tools, using VR creates a data driven, safe, and repeatable environment to sensitize learners to the sources of discrimination. Moreover, it offers a cost-effective and reliable tool to improve decision making and the values of social equality.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_3.png",
                "card_title": "Segment 1",
                "card_sub_title": " White male"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_3.png",
                "card_title": "Segment 2",
                "card_sub_title": "White female"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_3.png",
                "card_title": "Segment 3",
                "card_sub_title": "Black male"
            },
            {
                "background_image": "/sites/xr/files/2023-05/Tekka_background_3.png",
                "card_title": "Segment 4",
                "card_sub_title": "Black female"
            }
        ],
        "tags": [
            "Decision Making",
            "DEI",
            "Communication",
            "Team Dynamics"
        ],
        "learning_objectives": [
            "Elicit and trigger reactions to other team members signaling devaluation of members of negatively stereotyped groups.",
            "Data driven teaching used to uncover subconscious cognitions and dysfunctional cultures in teams and individuals",
            "Bring the unconscious cognitive biases to the awareness of executives and students, so they can identify them when elicited.",
            "Promote global awareness to the impact of discriminatory behaviors and their root causes",
            "Leverage diversity through attention allocation to support creative processes in teams. "
        ],
        "unique_features": [
            "Behavioral analytics",
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_mie51g6z",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/tower%201.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Zoe Kinias",
                "Modupe Akinola (Columbia Business School)",
                "Theodore Masters Waage",
                "Daniel Landau",
                "Alon Epstein"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "90 min - 1 day"
        }
    },
    {
        "id": "185",
        "title": "Shea Seeds: The AAK Kolo Nafaso Program",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/preview.00_00_51_25.Still001.png",
            "description": "<p>Designing sustainable supply chains</p>"
        },
        "description": "<p><a href=\"https://xr.insead.edu/playbooks/shea-seeds/summary\">Go to mini playbook</a><br><br>This immersive experience takes learners to the Shea Belt in south Saharan Western Africa where 4 million (mostly) women earn their livelihood from picking and processing Shea seeds. Shea butter has become a key ingredient in cosmetics as well as in food and confectionary industries. However, traditional supply chains left very little value in the hands of the growers, earning the equivalent of less than 1USD per month, while middlemen enjoyed all the proceedings and rise in demand. Leaners will be exposed and play an active role in how to transform outdated forms of commerce and supply chain management into more sustainable, as well as fair. Exposed to a very successful project developed by AAK, the global leader in Shea Butter processing and trade, participants will learn how to think about engaging with small holder suppliers to create value for all stakeholders in the value chain. This project is developed with and co-funded by AAK.&nbsp;</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/1666.jpeg",
                "card_title": "Segment 1",
                "card_sub_title": "Intro"
            },
            {
                "background_image": "/sites/xr/files/2023-05/1777.jpeg",
                "card_title": "Segment 2",
                "card_sub_title": "Kolo nafaso"
            },
            {
                "background_image": "/sites/xr/files/2023-05/6_0.PNG",
                "card_title": "Segment 3",
                "card_sub_title": "Quality processing"
            },
            {
                "background_image": "/sites/xr/files/2023-05/1_3.PNG",
                "card_title": "Segment 4",
                "card_sub_title": "Shea evacuation"
            },
            {
                "background_image": "/sites/xr/files/2023-05/4_0.PNG",
                "card_title": "Segment 5",
                "card_sub_title": "AAK operations"
            },
            {
                "background_image": "/sites/xr/files/2023-05/3_2.PNG",
                "card_title": "Segment 6",
                "card_sub_title": " Impact - community"
            },
            {
                "background_image": "/sites/xr/files/2023-05/mars%20arilock%20bacground_0.png",
                "card_title": "Segment 8",
                "card_sub_title": "Impact - market"
            },
            {
                "background_image": "/sites/xr/files/2023-05/5_0.PNG",
                "card_title": "segment 8",
                "card_sub_title": "AAK customers project"
            }
        ],
        "tags": [
            "Entrepreneurship",
            "Sustainability",
            "Creativity & Innovation",
            "Supply chain management",
            "Marketing",
            "Operations",
            "Service Process"
        ],
        "learning_objectives": [
            "Understanding the concept of sustainability in the perspective of supply chain management",
            "Realise value creation and value capturing within supply chains",
            "Design plans to create both financial benefits with positive social impact ",
            "Applying systematic creativity to create real impact",
            "Business as a force for good - leading sustainable growth"
        ],
        "unique_features": [
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_07wvwsgd",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Big_Size_Shea_V1.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Daniel Landau",
                "Alon Epstein",
                "Amitava Chattopadhyay"
            ],
            "duration_of_vr_seconds": "25",
            "type_of_experience": [
                "site visit"
            ],
            "recommended_learning_time": "90 min - 1/2 day"
        }
    },
    {
        "id": "190",
        "title": "Beyond Sustainability at Blue Hill",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/bl.png",
            "description": "<p>Beyond Sustainability at Blue Hill</p>"
        },
        "description": "<p>Innovation is about solving problems and ensuring a sustainable future is one of our greatest challenges. In the setting of the food industry, the case explores how organizations can use innovation to reimagine a system and lead transformation.</p><p>In the case, Chef Dan Barber, one the world’s top chefs reorganizes to create an innovation engine focused on rearchitecting the food system to make it regenerative—meaning it puts back more than it takes. Although set in the food industry, the case teaches lessons about innovation, organization change, and transformation relevant to any industry. For example, how do you organize for innovation? How do you help people change? How does the “soil” of an organization affect the quality of the work it does. These and many other lessons are relevant both to the sustainability challenge but also the innovation challenge faced by all organizations</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/mars%20airlock%20segmnet%2022.png",
                "card_title": "Segment 1",
                "card_sub_title": "Intro"
            },
            {
                "background_image": "/sites/xr/files/2023-05/mars%20arilock%20segment%202.png",
                "card_title": "Segment 2",
                "card_sub_title": "Morning prep"
            },
            {
                "background_image": "/sites/xr/files/2023-05/MF_background.png",
                "card_title": "Segment 3",
                "card_sub_title": "Pre service"
            },
            {
                "background_image": "/sites/xr/files/2023-05/MF_background_0.png",
                "card_title": "Segment 4",
                "card_sub_title": "Service"
            },
            {
                "background_image": "/sites/xr/files/2023-05/MF_segment%202.png",
                "card_title": "Segment 5",
                "card_sub_title": " Innovation & culture"
            },
            {
                "background_image": "/sites/xr/files/2023-08/AVOCADO-%20trailer_0.png",
                "card_title": "Segment 6",
                "card_sub_title": "Ecosystem"
            }
        ],
        "tags": [
            "Creativity & Innovation",
            "Operations",
            "Communication",
            "Leadership",
            "Management",
            "Operations",
            "Service Process",
            "Team Dynamics"
        ],
        "learning_objectives": [
            "Innovation models and their application in various industries",
            "Sustainability approaches – positive impact and growth",
            "How to adapt and apply innovation models across industries·      ",
            "New approaches to supply chain management",
            "The role of ecosystems - how to design them with a regenerative quality?",
            "How to manage and promote a culture of innovation?",
            "Motivating team members and creating a learning environment"
        ],
        "unique_features": [
            "Questions ",
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_j6eag8ef",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/blue%20background.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Nathan Furr",
                " Susannah Furr",
                "Andrew Shipilov"
            ],
            "duration_of_vr_seconds": "10",
            "type_of_experience": [
                "site visit"
            ],
            "recommended_learning_time": "90 min - 1/2 day"
        }
    },
    {
        "id": "192",
        "title": "Trust Games",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/trust%20background.png",
            "description": "<p>Confronting racial and gender biases</p>"
        },
        "description": "<p>In this immersive experience, learners will compete for a coveted slot in a prestigious virtual social club. Both the participant and the opponent try to maximize their credits which will determine their potential to be accepted. In order to accomplish this, they will have to trust, but will they find it in themselves to do so when the opponent is very different from them?</p><p>The essence of this experience is to explore in-group and out-group biases using 12 characters representing different genders, ethnicity, and demographics. Participants will be randomly assigned into a condition out of 10 available ones. Facilitators can choose which character participants meet,&nbsp; turning the class into a behavioral lab, eliciting social and cognitive biases. Find out and analyze how people react to various identities and how it affects the results of the exercise.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-05/mf_segment%203_0.png",
                "card_title": "Segment 1",
                "card_sub_title": "young/old"
            },
            {
                "background_image": "/sites/xr/files/2023-05/MF_segment%201.png",
                "card_title": "Segment 2",
                "card_sub_title": "hijab/no hijab"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20background.png",
                "card_title": "Segment 3",
                "card_sub_title": "black non-ethnic/black ethnic"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20background_0.png",
                "card_title": "Segment 4",
                "card_sub_title": "black/white"
            },
            {
                "background_image": "/sites/xr/files/kaltura/poster/spiral%20flow%20background.png",
                "card_title": "Segment 5",
                "card_sub_title": "ME ethnic/nonethnic"
            },
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/-wx9R-8uGiL--zr_bGaHU5kuFsp5uCm6YUR6YamqieM.jpg",
                "card_title": "Segment 6",
                "card_sub_title": "female/male"
            }
        ],
        "tags": [
            "DEI",
            "Decision Making",
            "Goal Setting",
            "Communication",
            "Consuling",
            "Leadership",
            "Management",
            "Negotiations",
            "OB",
            "Team Dynamics"
        ],
        "learning_objectives": [
            "Uncover underlying biases of trust and suspicion in interpersonal relations",
            "Understand the effects of social biases and inter-group dynamics on teams and organizations ",
            "Dynamics of giving vs. taking and their effect on emotions, self efficacy and sense of accomplishment "
        ],
        "unique_features": [
            "Questions ",
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_oa3856d0",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/trust%20background.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Kaisa Snellman",
                "Alon Epstein",
                "Daniel Landau",
                "Ithai Stern"
            ],
            "duration_of_vr_seconds": "7",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "60-90 min"
        }
    },
    {
        "id": "194",
        "title": "Pitching for the Metaverse",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-05/pitch%20background.png",
            "description": "<p>Biases in&nbsp;evaluating&nbsp;creative ideas</p>"
        },
        "description": "<p>The experience takes place in the offices of “Grassroots Ventures” VC. The VC is looking for early-stage ventures in the space of virtual worlds and the emerging Metaverse. Learners will take the role of a new junior partner in the VC and will join the investment committee for the first time. Today, the investment panel will meet two entrepreneurs, but only one can receive an investment from Grassroots Ventures. This immersive experience will challenge learners to acknowledge and confront their social and cognitive biases that come into play when evaluating creative ideas, and introduce tools to overcome them.&nbsp;</p><p>Participants will go through a series of evaluating and decision making sequences, challenged with asking the right questions and providing insights. What they don’t know is that they have been randomly assigned to a condition. How will this affect their judgment?</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/kaltura_thumbnails/cb7qqTaLQGrQ2vyvR1UaKkZBjBtUWryd_6jOtOR4Il0.jpg",
                "card_title": "Priming",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20background_1.png",
                "card_title": "Introduction to ventures & Tutorial",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20segment%202.png",
                "card_title": "XParenting pitch",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20segment%202_0.png",
                "card_title": "MindeSpace XR pitch",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "/sites/xr/files/2023-05/spiral%20flow%20segment%203.png",
                "card_title": "Panel discussion and decision making",
                "card_sub_title": "Segment 5"
            }
        ],
        "tags": [
            "Communication",
            "Consuling",
            "Creativity & Innovation",
            "Decision Making",
            "DEI",
            "Entrepreneurship",
            "Management",
            "OB",
            "Financial decision making"
        ],
        "learning_objectives": [
            "Confront social and cognitive biases when evaluating creative ideas and entrepreneurial ventures",
            "Acquire tools to improve idea pitching to decision makers (corporate venturing) and investors  ",
            "Optimize fund allocation in investments",
            "Reduce discrimination and biases in financial decision making",
            "Uncover inherent discriminatory behavior that are salient in corporate culture",
            "Improve the capability to leverage diversity and increase equality in organizations and society"
        ],
        "unique_features": [
            "Behavioral analytics"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_3rg2r6lj",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/pitch%20segment%201.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Ella Miron-Spektor",
                "Claudia Zeisberger (academic adviser) ",
                "Alon Epstein",
                "Daniel Landau"
            ],
            "duration_of_vr_seconds": "25",
            "type_of_experience": [
                "Interactive Simulation"
            ],
            "recommended_learning_time": "90 min - 1/2 day"
        }
    },
    {
        "id": "981",
        "title": "Design Thinking in Action – The shea supply chain",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-09/Background.png",
            "description": "<p dir=\"ltr\">Improving lives and processes through Design Thinking</p>"
        },
        "description": "<p dir=\"ltr\">Over 2 million women in Western Africa earn most of their living from collecting and processing shea seeds. This pivotal crop is an integral part of the culinary as well as the economic reality of the region.&nbsp;</p><p dir=\"ltr\">In this immersive experience, participants are assigned the role of consultants to the new Ghana country manager of AAK - a multinational that processes and trades in shea. Learners are tasked with diagnosing the key problems and are asked to provide recommendations on how to improve the value output. Guide participants through the process of identifying the problem, gaining intimate understanding of the process and clients, and develop solutions to any pain points uncovered. Participants will need to gather and analyze information from various sources to propose viable and helpful solutions while navigating through misconceptions and conflicting sources of information.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-08/MF_segment%202.png",
                "card_title": "Is there a problem?",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-08/mf_segment%203.png",
                "card_title": "Defining the problem",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-08/MF%20PPT%20-%20after%20review.pptx",
                "card_title": "high level interviews",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-08/MF_segment%204.png",
                "card_title": "Research - investigating the local environment & community",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "/sites/xr/files/2023-08/mf_segment%203_0.png",
                "card_title": "The Solution",
                "card_sub_title": "Segment 5"
            }
        ],
        "tags": [
            "Consuling",
            "Creativity & Innovation",
            "Decision Making",
            "Entrepreneurship",
            "Marketing",
            "Problem Solving",
            "Sustainability"
        ],
        "learning_objectives": [
            "Apply the design thinking model on a real live situation",
            " Improve understanding and capabilities in new product development and product adaptation",
            "Sensitize learners to cultural and geographical opportunities and constraints in product development",
            "Develop and design sustainable and responsible products",
            "The importance of sustainability and social impact in product development."
        ],
        "unique_features": [
            "interactive interviews, hotspots and self navigation"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_b4e7fq8x",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Background.png",
            "video_length": "00:25"
        },
        "video_detail": {
            "creators": [
                "Amitava Chattopadahyay",
                "Joerg Niessing"
            ],
            "duration_of_vr_seconds": "20",
            "type_of_experience": [
                "Interactive Documentary"
            ],
            "recommended_learning_time": "60-180 minute "
        }
    },
    {
        "id": "991",
        "title": "Mission to Mars - Building a Home",
        "playbook_link": null,
        "teaser": {
            "teaser_image": "https://xr.insead.edu/sites/xr/files/2023-03/Mars_BuildingaHomev2-gigapixel-standard-width-1900px%403x.webp",
            "description": "<p>Ideation and innovation in teamwork</p>"
        },
        "description": "<p>Ares Mining Base is doing well and is getting ready for the arrival of Ares 3 – the most expensive and largest expedition yet. In order to support three times as many people, a new base needs to be built fast. But a flaw in the design was found, and now a new design needs to be made and manufactured before Ares 3 leaves. Mission Control needs the input of Ares 1 &amp; 2 to produce an optimal solution for how to design the new base camp. The teams will&nbsp;set out on a design hackathon to provide proposals, and may the best team win! Learners will observe both teams working and interview team members, assess team dynamics and attitudes so they can recommend the best solution for Mission Control. This experience takes learners on an ideation and innovation journey, exploring what it really means to work as a team towards a challenging outcome and understanding that not everything that seems polished is actually effective.</p>",
        "cards": [
            {
                "background_image": "/sites/xr/files/2023-08/MF%20PPT%20-%20after%20review_0.pptx",
                "card_title": "Introduction",
                "card_sub_title": "Segment 1"
            },
            {
                "background_image": "/sites/xr/files/2023-08/blue%20segmnet%206.png",
                "card_title": "Ares 2",
                "card_sub_title": "Segment 2"
            },
            {
                "background_image": "/sites/xr/files/2023-08/blue%20segment%201.png",
                "card_title": "Interactive rooms",
                "card_sub_title": "Segment 3"
            },
            {
                "background_image": "/sites/xr/files/2023-03/SF_1%402x_1.webp",
                "card_title": "Remote work formats",
                "card_sub_title": "Segment 4"
            },
            {
                "background_image": "/sites/xr/files/2023-03/Group%206520%403x.webp",
                "card_title": "Outdoor scenes",
                "card_sub_title": "Segment 5"
            }
        ],
        "tags": [
            "Decision Making",
            "Entrepreneurship",
            "Team Dynamics",
            "Creativity & Innovation",
            "Leadership",
            "DEI",
            "Communication",
            "Management",
            "OB",
            "Problem Solving",
            "Teamwork"
        ],
        "learning_objectives": [
            "Managing creativity and innovation processes",
            "Ideation versus Implementation challenges - when to shift gears",
            "Fair Process Leadership for group creative decision making",
            "Observing and assessing team dynamics, attitudes and effectiveness ",
            "Uncovering subtle and hidden elements in group dynamics",
            "Discovering different attitudes towards conflict"
        ],
        "unique_features": [
            "Behavioral analytics",
            "Questions ",
            "Interactivity"
        ],
        "video": {
            "video_kaltura": [
                {
                    "entry_id": "1_29bub43o",
                    "partner_id": "2296822",
                    "uiconf_id": "39810392",
                    "domain": "cdnapisec.kaltura.com"
                }
            ],
            "video_poster_image": "/sites/xr/files/kaltura/poster/Screenshot%202023-09-28%20132435.png",
            "video_length": null
        },
        "video_detail": {
            "creators": [
                "Charles Galunic",
                "Jennifer Petriglieri",
                "Spencer Harrison"
            ],
            "duration_of_vr_seconds": "30",
            "type_of_experience": [
                "Immersive Simulation"
            ],
            "recommended_learning_time": " 1/2 - 1 day"
        }
    }
]
      }
      else{
        let resp = await axios.get(process.env.VUE_APP_PORTAL_URL + '/api/experiences') 
        tmp = resp.data.data              
      }
      commit('setDrupalExperiences', tmp)
    }
  },
  modules: {
    auth
  }
})
