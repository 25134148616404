import { render, staticRenderFns } from "./Sidebar2.vue?vue&type=template&id=961d2d80"
import script from "./Sidebar2.vue?vue&type=script&lang=js"
export * from "./Sidebar2.vue?vue&type=script&lang=js"
import style0 from "./Sidebar2.vue?vue&type=style&index=0&id=961d2d80&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports