import Vue from 'vue'
import VueRouter from 'vue-router'
//import Student from '../views/Student.vue'
import store from "../store";
import {Roles} from '@/roles.js'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Student,
  //   meta: {authorize: true}
  // },
  {
    path: '/sessions2',
    name: 'Sessions2',
    component: () => import(/* webpackChunkName: "sessions2" */ '../views/Sessions2.vue'),
    meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/sessions2/:id/manage',
    name: 'Session2Manage',
    component: () => import(/* webpackChunkName: "sessions2manage" */ '../views/Sessions2.vue'),
    meta: { authorize: true, roles: [Roles.Admin, Roles.OrgAdmin] }
  },
  {
    path: '/sessions2/:id',
    name: 'Session2Edit',
    component: () => import(/* webpackChunkName: "sessions2edit" */ '../views/Sessions2.vue'),
    meta: { authorize: true, roles: [Roles.Admin, Roles.OrgAdmin] }
  },
  {
    path: '/devicesimulator',
    name: 'DeviceSimulator',
    component: () => import(/* webpackChunkName: "devicesimulator" */ '../views/DeviceSimulator.vue'),
    //meta: {authorize: true, roles: [Roles.Professor,Roles.Admin]}
  },
  // {
  //   path: '/debug',
  //   name: 'Debug',
  //   component: () => import(/* webpackChunkName: "debug" */ '../views/Debug.vue'),
  // },
  {
    path: '/contentbuilder',
    name: 'ContentBuilder',
    component: () => import(/* webpackChunkName: "contentbuilder" */ '../views/ContentBuilder.vue'),
    // meta: {authorize: true, roles: [Roles.Professor,Roles.Admin]}
  },
  {
    path: '/authoring',
    name: 'Authoring',
    component: () => import(/* webpackChunkName: "authoring" */ '../views/Authoring.vue'),
    meta: {authorize: true, roles: [Roles.Admin]}
  },
  {
    path: '/authoring/master/:id',
    name: 'AuthoringMaster',
    component: () => import(/* webpackChunkName: "contentbuilder" */ '../views/ContentBuilder.vue'),
    meta: { authorize: true, roles: [Roles.Admin] }
  },
  {
    path: '/heatmaptest',
    name: 'HeatmapTest',
    component: () => import(/* webpackChunkName: "heatmaptest" */ '../views/HeatmapTest.vue'),
  },
  {
    path: '/presentation',
    name: 'Presentation',
    component: () => import(/* webpackChunkName: "presentation" */ '../views/Presentation.vue'),
    meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/dashboard/:sessionId?/:treeId?',
    redirect: '/dashboard2'
    // name: 'Dashboard',
    // component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    // meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/',
    redirect: '/upcoming'
    // name: 'Student',
    // component: () => import(/* webpackChunkName: "student" */ '../views/Student.vue'),
    // meta: {authorize: true, roles: [Roles.Student]}
    // name: 'DefaultUpcoming',
    // component: () => import(/* webpackChunkName: "defaultupcoming" */ '../views/Upcoming.vue'),
    // meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/users',
    redirect: '/users2'
    // name: 'Users',
    // component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    // meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/users2',
    name: 'Users2',
    component: () => import(/* webpackChunkName: "users2" */ '../views/Users2.vue'),
    meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/library',
    name: 'Library',
    component: () => import(/* webpackChunkName: "library" */ '../views/Library.vue'),
    meta: {authorize: true, roles: [Roles.Admin]}
  },
  {
    path: '/devices',
    redirect: '/devices2'
    // name: 'Devices',
    // component: () => import(/* webpackChunkName: "devices" */ '../views/Devices.vue'),
    // meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/devices2',
    name: 'Devices2',
    component: () => import(/* webpackChunkName: "devices2" */ '../views/Devices2.vue'),
    meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/devices3',
    name: 'Devices3',
    component: () => import(/* webpackChunkName: "devices3" */ '../views/Devices3.vue'),
    meta: {authorize: true, roles: [Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/sessions/:id',
    name: 'SessionEdit',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/Sessions.vue'),
    meta: { authorize: true, roles: [Roles.Professor, Roles.Admin, Roles.OrgAdmin] }
  },
  {
    path: '/sessions/:id/users',
    name: 'SessionUsers',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/Sessions.vue'),
    meta: { authorize: true, roles: [Roles.Professor, Roles.Admin, Roles.OrgAdmin] }
  },
  {
    path: '/sessions/:id/admins',
    name: 'SessionAdmins',
    component: () => import(/* webpackChunkName: "sessions" */ '../views/Sessions.vue'),
    meta: { authorize: true, roles: [Roles.Professor, Roles.Admin, Roles.OrgAdmin] }
  },
  {
    path: '/sessions',
    redirect: '/sessions2'
    // name: 'Sessions',
    // component: () => import(/* webpackChunkName: "sessions" */ '../views/Sessions.vue'),
    // meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {authorize: true}
  },
  {
    path: '/login/portal/:redirectPath?',
    name: 'LoginSSO',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),    
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {authorize: false}
  },
  {
    path: '/passwordreset',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "passwordreset" */ '../views/PasswordReset.vue'),
    meta: {authorize: false}
  },
  {
    path: '/confirmemail',
    name: 'EmailConfirm',
    component: () => import(/* webpackChunkName: "emailconfirm" */ '../views/EmailConfirm.vue'),
    meta: {authorize: false}
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {authorize: false}  
  },
  // v2
  {
    path: '/upcoming',
    name: 'Upcoming',
    component: () => import(/* webpackChunkName: "upcoming" */ '../views/Upcoming.vue'),
    meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/dashboard2/:sessionId?',
    name: 'Dashboard2',
    component: () => import(/* webpackChunkName: "dashboard2" */ '../views/Dashboard2.vue'),
    meta: {authorize: true, roles: [Roles.Professor,Roles.Admin,Roles.OrgAdmin]}
  },
  {
    path: '/xrlibrary/:experienceId?',
    name: 'XRLibrary',
    component: () => import(/* webpackChunkName: "xrlibrary" */ '../views/XRLibrary.vue'),
    meta: { authorize: true, roles: [Roles.Professor, Roles.Admin, Roles.OrgAdmin] }
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const animExceptions = [
  {from: 'Session2Edit', to: 'Sessions2'},
  {from: 'Sessions2', to: 'Session2Edit'},
  {from: 'Session2Manage', to: 'Sessions2'},
  {from: 'DefaultUpcoming', to: 'Upcoming'},
  {from: 'Upcoming', to: 'DefaultUpcoming'},
  {from: 'XRLibrary', to: 'XRLibrary'}
]

router.beforeEach((to, from, next) => { 
  if(!animExceptions.find(e => e.from == from.name && e.to == to.name))
    store.state.showLoader = true

  if (to.meta.authorize != undefined) {
      if (to.meta.authorize && !store.getters.isAuthenticated)    
        next("/login");             
      
      else if (to.meta.roles && (!store.getters.userRoles || !to.meta.roles.some(r => store.getters.userRoles.includes(r)))){
        // console.log("wtf1")
        store.getters.isProfessor || store.getters.isAdmin || store.getters.isOrgAdmin 
          ? next("/upcoming")
          : next("/")   
      }   
      else if(!to.meta.authorize && store.getters.isAuthenticated){
        // console.log("wtf2")
        store.getters.isProfessor || store.getters.isAdmin || store.getters.isOrgAdmin
        ? next("/upcoming") 
        : next("/")   
      } 

      else
        next();   
  }
  else
    next();
})
// eslint-disable-next-line
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    Vue.prototype.$nprogress.start()
  }
  next()
})
// eslint-disable-next-line
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  Vue.prototype.$nprogress.done()
})

export default router
