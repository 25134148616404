import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue"

import VCalendar from 'v-calendar'
Vue.use(VCalendar)
// Vue.use(VCalendar, {
//   // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
//   // ...,                // ...other defaults
// });

import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

Vue.config.productionTip = false

import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });
Vue.prototype.$nprogress = NProgress;

import Constants from '@/constants.js'
Vue.prototype.$constants = Constants

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

let sntry = process.env.VUE_APP_ENV == 'dev'

if(sntry){
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENV,
    // release: process.env.VUE_APP_RELEASE,
    dsn: "https://d313808e766cd7818dd5e289dc908306@o4507378811207680.ingest.de.sentry.io/4507378814943312",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.avris\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setUser(store.getters.userId ? { id: store.getters.userId } : null)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
